import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {

  connect() {
  }

  close(){
    this.element.classList.add('hidden')
  }
}
