import { Controller } from "@hotwired/stimulus";
import React from "react"
import * as ReactDOM from 'react-dom'
import NewLogEntry from '../components/forward/expeditions/NewLogEntry';

export default class extends Controller {
  connect() {
    const data = JSON.parse(this.data.get("expedition"));
    ReactDOM.render(
      <NewLogEntry expedition={data} />,
      this.element
    );
  }

  disconnect(){
    // destroy react component
  }
}
