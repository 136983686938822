import React from "react";
import * as ReactDOM from 'react-dom'
import PropTypes from "prop-types";
import axios from 'axios';

class NewLogEntry extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
    this.state = {value: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({value: event.target.value});
  }

  handleSubmit(event) {
    event.preventDefault();

    const url = '/api/v1/expeditions/'+this.props.expedition.id+'/log_entries.json';
    axios.post(url, {log_entry: {text: this.state.value}})

    this.setState({value: ''})

  }

  componentDidMount(){
  }

  render () {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col">
            <form className="mt-1">
              <label className="sr-only">New log entry ...</label>
              <textarea value={this.state.value} className="form-control form-control-flush" data-toggle="autosize" placeholder="New log entry" rows="4" onChange={this.handleChange}>
              </textarea>
            </form>
          </div>
          <div className="col-auto align-self-end">
            <div className="text-muted mb-2">
              <a className="text-reset mr-3" data-toggle="tooltip" href="#!" title="Save" onClick={this.handleSubmit}>
                Send
              </a>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

NewLogEntry.propTypes = {
  name: PropTypes.string
};
export default NewLogEntry
