import { Controller } from '@hotwired/stimulus'
import { Chart, Interaction } from 'chart.js';
import 'chartjs-adapter-luxon';


import {DateTime} from 'luxon';
import axios from 'axios';

import {CrosshairPlugin,Interpolate} from 'chartjs-plugin-crosshair';



export default class extends Controller {


  connect() {
    setTimeout(() => {
      this.setupChart();
    }, 200);
  }
  
  setupChart() {
    
    Chart.register(CrosshairPlugin);
    Interaction.modes.interpolate = Interpolate;

    let element = this.element
    let conditions = JSON.parse(this.data.get('conditions'))
    let timeseries = JSON.parse(this.data.get('timeseries'))

    let priceStart = this.data.get('start');
    let priceStop = this.data.get('stop');

    const CHART_COLORS = {
      red: 'rgb(255, 99, 132)',
      orange: 'rgb(255, 159, 64)',
      yellow: 'rgb(255, 205, 86)',
      green: 'rgb(75, 192, 192)',
      blue: 'rgb(54, 162, 235)',
      purple: 'rgb(153, 102, 255)',
      grey: 'rgb(201, 203, 207)',
      pink: 'rgb(214 31 105)',
    };
    
    let datasets = [
      {
        label: 'Price',
        yAxisID: 'yAxesLog',
        data: [],
        //fill: false,
        //pointBorderWidth: 2,
        pointRadius: 0,
        borderColor: CHART_COLORS.grey,
        borderWidth:1
      },
    ]

    conditions.map((c) => { 
      datasets.push(
        {
          borderColor: CHART_COLORS.purple,
          
          data: [
            {
              x: c.entered_at,
              y: c.condition_id
            },
            {
              x: c.exited_at,
              y: c.condition_id
            }

          ]
        }
      )
    });

    const options = {
      plugins: {
        tooltip: {
          mode: 'interpolate',
          intersect: false
        },
        legend: {
          display: false,
        },
        crosshair: {
          line: {
            color: 'black',  // crosshair line color
            width: 1        // crosshair line width
          },
          sync: {
            enabled: true,            // enable trace line syncing with other charts
            group: 1,                 // chart group
            suppressTooltips: true   // suppress tooltips when showing a synced tracer
          },
        },
      },
      scales: {
        x: {
          type: 'time',
          stacked: true,
          time: {
              unit: 'month'
          },
          grid: {
            display: false,
          },
        },
        y: {
          type: 'linear',
          ticks: {
            // forces step size to be 50 units
            stepSize: 1,
            padding: 0
          }
        },
        yAxesLog: {
          type: 'logarithmic',
          position: 'right',
          grid: {
            display: false,
          },
        },
        yIndicator: {
          type: 'linear',
          position: 'right',
          grid: {
            display: false,
          },
        },
      }
    }
    


    this.chart = new Chart(element.getContext('2d'), {
      // @ts-ignore
      type: 'line', // @ts-ignore
      data: {
        datasets: datasets
      }, // @ts-ignore
      options: options,
    })

    this.getPrice(this.chart, timeseries, priceStart, priceStop);
   
  }

  getPrice(chart, timeseries, startDate, stopDate){
    const timeframe = '1D';//this.props.timeframe;

    //const url = '/api/v1/candles/BTC-USD.json?timeframe='+timeframe+'&start_date='+startDate+'&stop_date='+stopDate;
    const url = '/api/v1/timeseries/'+timeseries.id+'?start='+startDate+'&stop='+stopDate;

    axios.get(url)
      .then(
        (response) => {
          let dataSet = chart.data.datasets[0];
          dataSet.data = response.data.data;
          let j=0;
          let colors = ['rgb(255, 159, 64)', 'rgb(75, 192, 192)', 'rgb(153, 102, 255)']
          response.data.indicators.map((i) => {
            let id = 'yAxesLog';
            if(j==2){
              id = 'yIndicator'
            }
            let set = {data: i, borderColor: colors[j], pointRadius: 0, yAxisID: id, borderWidth:0.5};

            chart.data.datasets.push(set);
            j+=1;
          })
          chart.update();
          //this.paintDataSet(dataSet, colors);
        }
      );
  }
  disconnect(){
    if(this.chart){
      this.chart.destroy()
    }
    this.chart = undefined
  }


}
