import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-luxon';

import { OhlcElement, OhlcController, CandlestickElement, CandlestickController } from 'chartjs-chart-financial'
//import moment from 'moment';
import axios from 'axios';
import trading from './trading';

Chart.register(OhlcElement, OhlcController, CandlestickElement, CandlestickController);

export default class extends Controller {
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    chartData: Object,
    options: Object
  }
  invers = true;
  kind = 'rainbow';

  connect() {
    let element = this.element
    let timeseries = JSON.parse(this.data.get('timeseries'))
    let interval = this.data.get('interval')
    let timeframe = this.data.get('timeframe')

    const options = {
      scales: {
        y: {
          type: 'linear'
        }
      }
    }
    
    const colors = {
      up: '#4338C9',
			down: '#BE185D',
			unchanged: '#4338C9',
    }

    const dataset = {
      datasets: [
        {
          label: timeseries.name +' ' + interval+' '+ timeframe,
          data: [],
          color: {
            up: colors.up,
            down: colors.down,
            unchanged: colors.unchanged,
          },
          borderColor: {
            up: colors.up,
            down: colors.down,
            unchanged: colors.up
          },
          type: 'candlestick',
        },
      ],
    };

    this.chart = new Chart(element.getContext('2d'), {
      // @ts-ignore
      type: 'candlestick', // @ts-ignore
      data: dataset, // @ts-ignore
      options: options,
    })

    this.buildTimeseriesChart(this.chart, timeseries, interval, timeframe);
  }

  // Bind an action on this method
  async update() {
   
  }

  disconnect(){
    this.chart.destroy()
    this.chart = undefined
  }

  buildTimeseriesChart(chart, timeseries, interval, timeframe){

    const url = '/api/v1/timeseries/' + timeseries.id + '?interval=' + interval + '&extra_fields=true' + '&timeframe='+timeframe + '&kind=candlestick';
    console.log(url)
    axios.get(url)
      .then(
        (response) => {
          let data = response.data.data;
          let dataSet = chart.data.datasets[0];
          dataSet.data = trading.makeOHLC(data);
          chart.update();
        }
      );
  }

}
