import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {


  connect() {
    this.sidebar = document.querySelector('#mobileSidebar')

  }

  open(){
    this.sidebar.classList.remove('hidden')
  }
}
