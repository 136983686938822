import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  initialize(){
  }

  connect() {
    this.slideover = document.querySelector('#slideover-target')
  }

  showSlideover(){
    this.slideover.classList.remove("hidden")
  }

  hideSlideover(){
    this.slideover.classList.add("hidden")
  }

  disconnect(){
  }
}
