import { Controller } from '@hotwired/stimulus'
import Chart from 'chart.js/auto'
import 'chartjs-adapter-moment';
import moment from 'moment';
import axios from 'axios';
import trading from './trading';

export default class extends Controller {
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    chartData: Object,
    options: Object
  }
  invers = true;
  kind = 'rainbow';

  connect() {
    //const element: HTMLCanvasElement = this.hasCanvasTarget ? this.canvasTarget : this.element
    let element = this.element
    let timeseries = JSON.parse(this.data.get('timeseries'))
    let interval = this.data.get('interval')

    this.invers = this.data.get('invers') || true;
    this.kind = this.data.get('kind') || 'rainbow';

    if(this.hasCanvasTarget){
      element =this.canvasTarget
    }

    let options = this.chartOptions(timeseries);

    let scaleType = 'linear';
    if(timeseries.log_scale){
      scaleType='logarithmic'
    }

    let pointRadius = 0;
    let borderColor = '#d63384';
    if (timeseries.colorize){
      pointRadius = 2
      borderColor = 'black';
    }

    const data = {
      datasets: [
        {
          label: timeseries.name,
          yAxisID: 'yAxesLin',
          data: [],
          fill: false,
          borderWidth: 0.5,
          pointRadius: pointRadius,
          borderColor: borderColor,
        },
        {
          label: 'Price',
          yAxisID: 'yAxesLog',
          data: [],
          //fill: false,
          //pointBorderWidth: 2,
          pointRadius: 0,
          borderColor: 'white',
          borderWidth:0.5
        },
      ],
    };



    this.chart = new Chart(element.getContext('2d'), {
      // @ts-ignore
      type: 'line', // @ts-ignore
      data: data, // @ts-ignore
      options: options,
    })

    this.buildTimeseriesChart(this.chart, timeseries, interval);
  }

  chartOptions(timeseries){
    let scaleType = 'linear';
    if(timeseries.log_scale){
      scaleType='logarithmic'
    }

    const options = {
      plugins: {
        autocolors: false,
        annotation: {
          annotations: {
          }
        },
        legend: {
          display: false
        },
        tooltip: {
          enabled: true,
          //mode: 'interpolate',
          //intersect: true
        },

      },
      animation: {
        duration: 0
      },
      scales: {
        xAxes: {
          type: 'time',
          stacked: true,
          time: {
              unit: 'month'
          },
          grid: {
            display: false,
          },
        },
        yAxesLin: {
          type: scaleType,
          position: 'left',
          grid: {
            display: false,
          },
        },
        yAxesLog: {
          type: 'logarithmic',
          position: 'right',
          grid: {
            display: false,
          },
        },
      },
    };
    return options;
  }
  // Bind an action on this method
  async update() {
    console.log("hello from update")
  }

  disconnect(){
    this.chart.destroy()
    this.chart = undefined
  }

  getPrice(chart, timeseries, interval, startDate, stopDate){
    const timeframe = '1D';//this.props.timeframe;

    //const url = '/api/v1/candles/BTC-USD.json?timeframe='+timeframe+'&start_date='+startDate+'&stop_date='+stopDate;
    const url = '/api/v1/timeseries/'+timeseries.timeseries_id+'?interval=' + interval

    axios.get(url)
      .then(
        (response) => {
          let dataSet = chart.data.datasets[1];
          dataSet.data = response.data.data;
          chart.update();
          //this.paintDataSet(dataSet, colors);
        }
      );
  }

  paintDataSet(chart, dataSet, colors){
    let diff = colors.length - dataSet.data.length;

    while(diff > 0){
      //colors.shift("#ff5733")
      diff -= 1;
    }



    dataSet.pointBackgroundColor = colors;
    dataSet.pointBorderColor = colors;
    chart.update();

  }

  buildColorSpectrum(values){
    // rescale the data 0-100

    if(true){
      values = trading.reScaleArray(values, 0, 100)
    }



    let colors = trading.buildSpectrumColors(values, this.invers, this.kind);
    //this.setState({colors: colors});
    return colors;
  }

  buildTimeseriesChart(chart, timeseries, interval){

    const url = '/api/v1/timeseries/' + timeseries.id + '?interval=' + interval

    axios.get(url)
      .then(
        (response) => {
          // plot chart
          console.log(response)
          let data = response.data.data;
          console.log(chart)
          let dataSet = chart.data.datasets[0];
          console.log("dataset", dataSet)
          dataSet.data = data;
          chart.update();

          // paint colors
          let values = [];
          data.map(m => { values.push(m.y) });

          if(timeseries.colorize){
            let colors = this.buildColorSpectrum(values);
            this.paintDataSet(chart, dataSet, colors);
          }

          //this.addAnnotations();

          // fetch price
          if(timeseries.source != 'coinbase' && timeseries.timeseries_id) {
            const stopDate = response.data.data[0].x;
            const startDate = response.data.data[response.data.data.length - 1].x
            this.getPrice(chart, timeseries, interval, startDate, stopDate)
          }


        }
      );
  }

}
