import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="sidebar"
export default class extends Controller {
  static targets = ['container']

  connect() {
  }

  close(){
    this.containerTarget.classList.add('hidden')
  }

  closeBackground(){
    this.close();
  }

  open(){
    this.containerTarget.classList.remove('hidden')
  }

  closeWithKeyboard(e){
    if (e.code == "Escape") {
      this.close();
    }
  }

  submitEnd(e){
    if (e.detail.success) {
      this.close()
    }
  }
}
